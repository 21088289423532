<template>
  <div id="pendapatan-kasir">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Dari </template>
                  <b-form-datepicker
                    style="width: 100%"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    no-flip
                    locale="id"
                    v-model="data_search.tanggal_mulai"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Sampai </template>
                  <b-form-datepicker
                    style="width: 100%"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    no-flip
                    locale="id"
                    v-model="data_search.tanggal_selesai"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <div style="width: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <h6 style="margin: 0; font-weight: bold">Puskesmas</h6>
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 30px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_puskesmas"
                        name="check_puskesmas"
                        v-model="checked"
                        @change="toggleSelect()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr />
                <b-row style="padding-left: 7.5px; margin-top: 15px">
                  <b-col
                    v-for="(item, idx) in options_puskesmas"
                    :key="idx"
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                  >
                    <b-form-checkbox v-model="item.checked" @change="addSelect()">
                      {{ item.nama_puskesmas }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="display: flex; justify-content: flex-start">
                  <b-button @click="getDataKunjungan()" variant="primary" class="mr-2"
                    >Tampilkan</b-button
                  >
                  <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                  <b-button variant="warning" @click="resetDataKunjungan">Reset</b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center" cols="12" sm="12" md="12" lg="12" xl="12">
                <h5>
                  <strong>LAPORAN HARIAN - PENDAPATAN KASIR</strong>
                </h5>
                <p>{{ $moment(data_search.tanggal_mulai).format("LL") }} - {{ $moment(data_search.tanggal_selesai).format("LL") }}</p>
              </b-col>
            </b-row>

            
            <!-- <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h5 >
                  <strong>Tanggal: {{ data_search.tanggal_mulai }} - {{ data_search.tanggal_selesai }}</strong>
                </h5>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <!-- <div class="text-end">Periode : {{ $moment(data_search.tanggal_mulai).format("LL") }} - {{ $moment(data_search.tanggal_selesai).format("LL") }}</div> -->
                <b-table-simple striped>
                  <b-thead>
                    <b-tr class="text-center">
                      <b-th rowspan="2">No.</b-th>
                      <b-th colspan="2">Jenis / Kelompok</b-th>
                      <b-th rowspan="2">Quantity</b-th>
                      <b-th rowspan="2">Jumlah</b-th>
                      <b-th rowspan="2">Total</b-th>
                    </b-tr>

                    <b-tr class="text-center">
                      <b-th>Kode</b-th>
                      <b-th>Nama</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td colspan="6">
                        <div class="top-text-variant">RUANGAN</div>
                      </b-td>
                    </b-tr>
                    <template v-if="item_poli.length">
                      <b-tr v-for="(item, idx) in item_poli" :key="idx + 'poli'">
                        <b-td class="text-center">{{ idx + 1 }}</b-td>
                        <b-td class="text-center">{{ item.kode_poli_bpjs }}</b-td>
                        <b-td>{{ item.nama_poli }}</b-td>
                        <b-td>{{ $numberFormat(item.jumlah) }}</b-td>
                        <b-td class="text-right">{{ $numberFormat(item.total_harga, "IDR") }}</b-td>
                        <b-td></b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center text-muted" colspan="6">
                          <i>Tidak Ada Data</i>
                        </b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="5" variant="secondary">
                        <h6>
                          <strong>Total</strong>
                        </h6>
                      </b-td>
                      <b-td variant="secondary" class="text-right">
                        <h6 v-if="item_poli.length">
                          <strong>{{ $numberFormat(sub_total_harga.poli, "IDR") }}</strong>
                        </h6>
                        <h6 v-else>
                          <strong>-</strong>
                        </h6>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td colspan="6">
                        <div class="top-text-variant">TINDAKAN</div>
                      </b-td>
                    </b-tr>
                    <template v-if="item_tindakan.length">
                      <b-tr v-for="(item, idx) in item_tindakan" :key="idx + 'tindakan'">
                        <b-td class="text-center">{{ idx + 1 }}</b-td>
                        <b-td class="text-center">{{ item.kd_tindakan }}</b-td>
                        <b-td>{{ item.nama_tindakan }}</b-td>
                        <b-td>{{ $numberFormat(item.jumlah) }}</b-td>
                        <b-td class="text-right">{{ $numberFormat(item.total_harga, "IDR") }}</b-td>
                        <b-td></b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center text-muted" colspan="6">
                          <i>Tidak Ada Data</i>
                        </b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="5" variant="secondary">
                        <h6>
                          <strong>Total</strong>
                        </h6>
                      </b-td>
                      <b-td variant="secondary" class="text-right">
                        <h6 v-if="item_tindakan.length">
                          <strong>{{ $numberFormat(sub_total_harga.tindakan, "IDR") }}</strong>
                        </h6>
                        <h6 v-else>
                          <strong>-</strong>
                        </h6>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td colspan="6">
                        <div class="top-text-variant">LABORATORIUM</div>
                        <!-- <h5>Laboratorium</h5> -->
                      </b-td>
                    </b-tr>
                    <template v-if="item_lab.length">
                      <b-tr v-for="(item, idx) in item_lab" :key="idx + 'lab'">
                        <b-td class="text-center">{{ idx + 1 }}</b-td>
                        <b-td class="text-center">{{ item.kd_laboratorium }}</b-td>
                        <b-td>{{ item.nama_tindakan_laboratorium }}</b-td>
                        <b-td>{{ $numberFormat(item.jumlah) }}</b-td>
                        <b-td class="text-right">{{ $numberFormat(item.total_harga, "IDR") }}</b-td>
                        <b-td></b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center text-muted" colspan="6">
                          <i>Tidak Ada Data</i>
                        </b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="5" variant="secondary">
                        <h6>
                          <strong>Total</strong>
                        </h6>
                      </b-td>
                      <b-td variant="secondary" class="text-right">
                        <h6 v-if="item_lab.length">
                          <strong>{{ $numberFormat(sub_total_harga.laboratorium, "IDR") }}</strong>
                        </h6>
                        <h6 v-else>
                          <strong>-</strong>
                        </h6>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td colspan="6">
                        <div class="top-text-variant">PAKET LABORATORIUM</div>
                        <!-- <h5>Paket Laboratorium</h5> -->
                      </b-td>
                    </b-tr>
                    <template v-if="item_paket_lab.length">
                      <b-tr v-for="(item, idx) in item_paket_lab" :key="idx + 'paket'">
                        <b-td class="text-center">{{ idx + 1 }}</b-td>
                        <b-td class="text-center">{{ item.kd_paket_laboratorium }}</b-td>
                        <b-td>{{ item.nama_paket_pemeriksaan }}</b-td>
                        <b-td>{{ $numberFormat(item.jumlah) }}</b-td>
                        <b-td class="text-right">{{ $numberFormat(item.total_harga, "IDR") }}</b-td>
                        <b-td></b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center text-muted" colspan="6">
                          <i>Tidak Ada Data</i>
                        </b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="5" variant="secondary">
                        <h6>
                          <strong>Total</strong>
                        </h6>
                      </b-td>
                      <b-td variant="secondary" class="text-right">
                        <h6 v-if="item_paket_lab.length">
                          <strong>{{ $numberFormat(sub_total_harga.paket_laboratorium, "IDR") }}</strong>
                        </h6>
                        <h6 v-else>
                          <strong>-</strong>
                        </h6>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      options_kecamatan: [],
      options_kelurahan: [],
      options_diagnosa: [],
      data_search: {
        tanggal_mulai: null,
        tanggal_selesai: null,
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        // halaman: 0,
        // jumlah: 1000,
      },
      item_poli: [],
      item_tindakan: [],
      item_lab: [],
      item_paket_lab: [],
      sub_total_harga: {},
      checked: false,
      checked_poli: "",
      select_puskesmas: [],
      select_poli: [],
      options_puskesmas: [],
      options_poli: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  activated() {
    this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD");
    this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD");
    this.getData();
    this.getDataKunjungan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;
      let puskesmas = await vm.$axios.post("/puskesmas/list");
      if (puskesmas.data.data.length) {
        vm.options_puskesmas = puskesmas.data.data;
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
          let x = vm.options_puskesmas[i];
          x.checked = false;
        }
      }
      let poli = await vm.$axios.post("/ms_poli/list");
      if (poli.data.data.length) {
        vm.options_poli = poli.data.data;
        for (let i = 0; i < vm.options_poli.length; i++) {
          let x = vm.options_poli[i];
          x.checked = false;
        }
      }
    },
    getDataKunjungan() {
      let vm = this;
      if (vm.select_puskesmas.length) {
        vm.data_search.bulk_puskesmas_id = vm.select_puskesmas;
      }
      if (vm.select_poli.length) {
        vm.data_search.bulk_ms_poli_id = vm.select_poli;
      }

      vm.item_poli = []
      vm.item_tindakan = []
      vm.item_lab = []
      vm.item_paket_lab = []
      
      vm.$axios
        .post("/kunjungan/laporan_harian_pendapatan_kasir", vm.data_search)
        .then((res) => {
          // console.log(res, "ini respon");
          if (res.data.status == 200) {
            let x = res.data.data
            for (const key in x) {
              if (Object.hasOwnProperty.call(x, key)) {
                const element = x[key];
                // console.log("element >>>>>>", element);
                if (key == 'poli') {
                  vm.item_poli = element
                  vm.totalHarga(key, element)
                }
                if (key == 'tindakan') {
                  vm.item_tindakan = element
                  vm.totalHarga(key, element)
                }
                if (key == 'laboratorium') {
                  vm.item_lab = element
                  vm.totalHarga(key, element)
                }
                if (key == 'paket_laboratorium') {
                  vm.item_paket_lab = element
                  vm.totalHarga(key, element)
                }
              }
            }

          } else {
            vm.items = [];
            vm.$store.commit("set_alert", {
              msg: "TIDAK ADA DATA",
              variant: "danger",
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$store.commit("set_alert", {
            msg: err.message,
            variant: "danger",
            showing: true,
          });
        });
    },
    totalHarga(key, val) {
      let subTotal = 0
      for (let i = 0; i < val.length; i++) {
        const _el = val[i];
        // subTotal += _el.jumlah * _el.total_harga
        subTotal += _el.total_harga
      }
      this.sub_total_harga[key] = subTotal
    },
    toggleSelect: function () {
      let select = this.checked;
      this.options_puskesmas.forEach(function (data) {
        data.checked = select;
      });
      this.select_puskesmas = [];
      for (let i = 0; i < this.options_puskesmas.length; i++) {
        let x = this.options_puskesmas[i];
        if (x.checked == true) {
          this.select_puskesmas.push(x.id);
        }
      }
      this.$forceUpdate();
    },
    toggleSelectPoli: function () {
      let select = this.checked_poli;
      // console.log(this.checked_poli);
      this.options_poli.forEach(function (data) {
        data.checked = select;
      });
      this.select_poli = [];
      for (let i = 0; i < this.options_poli.length; i++) {
        let x = this.options_poli[i];
        if (x.checked == true) {
          this.select_poli.push(x.ms_poli_id);
        }
      }
      this.$forceUpdate();
    },
    addSelect() {
      let vm = this;
      vm.select_puskesmas = [];
      for (let i = 0; i < vm.options_puskesmas.length; i++) {
        let x = vm.options_puskesmas[i];
        if (x.checked == true) {
          vm.select_puskesmas.push(x.id);
        }
      }
    },
    addSelectPoli() {
      let vm = this;
      vm.select_poli = [];
      for (let i = 0; i < vm.options_poli.length; i++) {
        let x = vm.options_poli[i];
        if (x.checked == true) {
          vm.select_poli.push(x.ms_poli_id);
        }
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
    resetDataKunjungan() {
      this.data_search = {
        tanggal_mulai: this.$moment().format("YYYY-MM-DD"),
        tanggal_selesai: this.$moment().format("YYYY-MM-DD"),
        bulk_ms_poli_id: [],
        bulk_puskesmas_id: [],
        halaman: 0,
        jumlah: 1000,
      }
      this.select_puskesmas = []
      this.select_poli = []
      this.getData()
      this.getDataKunjungan()
    },
  },
};
</script>

<style>
#pendapatan-kasir .custom-table td {
  padding: 0 !important;
  font-size: 14px;
  font-weight: bold;
  border: hidden !important;
}

#pendapatan-kasir .custom-control-label {
  padding-top: 2.5px;
}
.top-text-variant {
  font-size: 10px;
}
</style>
